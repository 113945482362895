import {BaseWebsiteResource} from './base-website-resource';
import {Course} from './course';
import {Location} from './location';

export class CourseInstanceDate {
  public formattedDate: string;

  public startDate: Date;
  public endDate: Date;

  private _startTime: string;
  private _endTime: string;

  private startHours: number = 0;
  private startMinutes: number = 0;
  private endHours: number = 0;
  private endMinutes: number = 0;

  public set date(date: Date | undefined) {
    if (!date) {
      return;
    }
    this.startDate = new Date(date.toISOString());
    this.startDate.setHours(this.startHours);
    this.startDate.setMinutes(this.startMinutes);
    this.endDate = new Date(date.toISOString());
    this.endDate.setHours(this.endHours);
    this.endDate.setMinutes(this.endMinutes);
  }

  public get date(): Date | undefined {
    return this.startDate;
  }

  public set startTime(time: string) {
    this._startTime = time;
    const [hours, minutes] = time.split(':');
    this.startHours = parseInt(hours);
    this.startMinutes = parseInt(minutes);

    if (isNaN(this.startHours)) {
      this.startHours = 0;
    }
    if (isNaN(this.startMinutes)) {
      this.startMinutes = 0;
    }

    this.startDate.setHours(this.startHours);
    this.startDate.setMinutes(this.startMinutes);
  }

  public get startTime(): string {
    return this._startTime;
  }

  public set endTime(time: string) {
    this._endTime = time;
    const [hours, minutes] = time.split(':');
    this.endHours = parseInt(hours);
    this.endMinutes = parseInt(minutes);

    if (isNaN(this.endHours)) {
      this.endHours = 0;
    }
    if (isNaN(this.endMinutes)) {
      this.endMinutes = 0;
    }

    this.endDate.setHours(this.endHours);
    this.endDate.setMinutes(this.endMinutes);
  }

  public get endTime(): string {
    return this._endTime;
  }

  public get title(): string {
    return `${new Intl.DateTimeFormat('de-DE', {
      weekday: "short",
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    }).format(this.startDate)}, ${this.startTime} – ${this.endTime} Uhr`;
  }

  constructor(date: any = {}) {
    this.startDate = date.startDate ? new Date(date.startDate) : new Date();
    this.endDate = date.endDate ? new Date(date.endDate) : new Date();

    this.formattedDate = new Intl.DateTimeFormat('de-DE', {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    }).format(this.startDate);
    this.startHours = this.startDate.getHours();
    this.startMinutes = this.startDate.getMinutes();
    this.endHours = this.endDate.getHours();
    this.endMinutes = this.endDate.getMinutes();

    this._startTime = `${this.startHours.toString().padStart(2, "0")}:${this.startMinutes.toString().padStart(2, "0")}`
        .replaceAll('NaN', '')
        .replace(/^:$/, '');
    this._endTime = `${this.endHours.toString().padStart(2, "0")}:${this.endMinutes.toString().padStart(2, "0")}`
        .replaceAll('NaN', '')
        .replace(/^:$/, '');
  }

  public toJson(): any {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
    };
  }
}

export class CourseInstance extends BaseWebsiteResource {
  public title: string;
  public totalSeats: number;
  public dates: CourseInstanceDate[];
  public course: Course;
  public location: Location | null;

  constructor(course: any = {}) {
    super(course);
    this.title = course.title ?? '';
    this.totalSeats = course.totalSeats ?? 0;
    this.dates = (course.dates ?? []).map(
      (date: any) => new CourseInstanceDate(date),
    );
    this.course = new Course(course.course);
    this.location = course.location ? new Location(course.location) : null;
  }

  private get startDate(): Date {
    return this.dates.sort((a, b) => {
      return a.startDate.getTime() - b.startDate.getTime();
    })[0].startDate;
  }

  private get endDate(): Date {
    return this.dates.sort((a, b) => {
      return b.startDate.getTime() - a.startDate.getTime();
    })[0].endDate;
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      title: this.title,
      totalSeats: this.totalSeats,
      dates: this.dates.map((date: CourseInstanceDate) => date.toJson()),
      course: this.course.toRef(),
      location: this.location?.toRef(),
    };
  }

  public get dateLabel(): string {
    return `${new Intl.DateTimeFormat('de-DE', {
      weekday: "short",
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    }).format(this.startDate)} - ${new Intl.DateTimeFormat('de-DE', {
      weekday: "short",
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    }).format(this.endDate)}`;
  }

  override getLabel(): string {
    return `${this.title} (${this.dateLabel}${this.location?.getLabel() ? ', ' + this.location?.getLabel() : ''})`;
  }

  public override getSearchIndex(): string[] {
    return [...super.getSearchIndex(), this.title];
  }

  public override clone(): CourseInstance {
    return new CourseInstance({
      ...this.toJson(),
      id: undefined,
      course: this.course.toJson(),
      location: this.location?.toJson()
    });
  }
}
